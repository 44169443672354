"use client";
import {
  Box,
  // IconButton,
  // InputAdornment,
  Pagination,
  // TextField,
  Typography,
} from "@mui/material";
// import { Search as SearchIcon } from "@mui/icons-material";
// import { getTemplatePreset } from "../api/templateManagement";
// import { getUserExperiments } from "../api/userManagement";
import ExperimentCard from "./ExperimentCard";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

export default function Experiments({
  experiments,
  setError,
  setMessage,
  clearAlerts,
  loading,
  setLoading,
  isRunning,
  setIsRunning,
  setExperiments,
  refreshExperiments,
  browserInfo,
  unsupportedModalOpen,
  setUnsupportedModalOpen,
  dontShowUnsupportedModal,
  setDontShowUnsupportedModal,
  setPostModalAction,
  totalCount,
  page,
  setPage,
  limit,
}) {
  // const [search, setSearch] = useState("");
  const router = useRouter();
  let plainIsRunning = false;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        overflow: "auto",
      }}
    >
      <Box sx={{ flexGrow: 1, p: 0, width: "100%" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="end"
          sx={{
            mb: "10px",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "flex-end" },
            gap: { xs: "10px", md: "0" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mt: 1,
              mb: 2,
              fontSize: "1.3rem",
              fontWeight: "bold",
              textAlign: { xs: "center", md: "start" },
            }}
          >
            My Experiments
          </Typography>
          {/* <Box>
            <TextField
              id="outlined-search"
              label="search"
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "text.primary",
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "text.secondary",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "text.primary",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
          {experiments &&
            experiments.count > 0 &&
            browserInfo !== null &&
            experiments.results.map((experiment) => {
              if (
                experiment.state === "running" ||
                experiment.state === "initializing"
              ) {
                plainIsRunning = true;
              }
              return (
                <ExperimentCard
                  key={"all-" + experiment.experiment_id}
                  experiment={experiment}
                  setError={setError}
                  setMessage={setMessage}
                  clearAlerts={clearAlerts}
                  loading={loading}
                  setLoading={setLoading}
                  isRunning={isRunning}
                  setIsRunning={setIsRunning}
                  setExperiments={setExperiments}
                  refreshExperiments={refreshExperiments}
                  router={router}
                  browserInfo={browserInfo}
                  unsupportedModalOpen={unsupportedModalOpen}
                  setUnsupportedModalOpen={setUnsupportedModalOpen}
                  dontShowUnsupportedModal={dontShowUnsupportedModal}
                  setDontShowUnsupportedModal={setDontShowUnsupportedModal}
                  setPostModalAction={setPostModalAction}
                />
              );
            })}
        </Box>

        <Pagination
          count={Math.ceil(totalCount / limit)}
          page={page}
          onChange={(event, value) => setPage(value)}
          sx={{
            justifyContent: "center",
            marginTop: "20px",
            "& ul": {
              // target the ul element inside the Pagination
              justifyContent: "center",
              marginTop: "20px",
            },
          }}
        />
      </Box>
      {useEffect(() => {
        setIsRunning(plainIsRunning); // Update state *after* the render phase, to avoid rerender while rendering
      }, [plainIsRunning, setIsRunning])}
    </Box>
  );
}
