"use client";
import { useState, useEffect } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { useAuth } from "../../util/auth";
import getBrowserInfo from "../../util/getBrowserInfo";
import { localLogger, logger } from "../../util/logger";
import { refreshExperiments } from "../lab-experiments/utils";
import { getTutorialData } from "../../api/tutorials";
import { getUserExperiments } from "../../api/userManagement";
import Experiments from "../lab-experiments/Experiments";
import SectionHeader from "../SectionHeader";
import UnsupportedModal from "../UnsupportedModal";
import TiltCard from "./TiltCard";
import BookModal from "../BookModal";
import { useRouter } from "next/navigation";

function LabSection() {
  // General
  const auth = useAuth();
  const router = useRouter();
  const accessToken = auth.user?.accessToken;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  // const [planSnackbarOpen, setPlanSnackbarOpen] = useState(false);
  // Tutorial
  const [tutorialOpen, setTutorialOpen] = useState(false);
  // Experiments
  const [experiments, setExperiments] = useState({ count: -1, results: [] });
  const [isRunning, setIsRunning] = useState(false);
  // Pagination
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(1);
  const limit = 10; // Items per page
  // Browser/BV compability checks
  const [browserInfo, setBrowserInfo] = useState(null);
  const [unsupportedModalOpen, setUnsupportedModalOpen] = useState(false);
  const [dontShowUnsupportedModal, setDontShowUnsupportedModal] =
    useState(false);
  const [postModalAction, setPostModalAction] = useState(null);

  // Fetch browser info
  useEffect(() => {
    async function fetchBrowserInfo() {
      const info = await getBrowserInfo();
      logger("browser info:", info);
      setBrowserInfo(info);
    }

    fetchBrowserInfo();

    if (typeof window !== "undefined") {
      setDontShowUnsupportedModal(
        localStorage.getItem("dontShowIntelMacWarning") ? true : false
      );
      if (!localStorage.getItem("hasSignedIn")) {
        localStorage.setItem("hasSignedIn", "true");
      }
    }
  }, []);

  // useEffect(() => {
  //   const authRoles = auth.user.roles;
  //   if (authRoles) {
  //     // setRoles(authRoles);
  //     console.log("auth.user in labsection:", auth.user);
  //     const isPaid = router.query.paid;
  //     if (
  //       isPaid &&
  //       (authRoles?.includes("pro-user") ||
  //         authRoles?.includes("ultimate-user"))
  //     ) {
  //       setPlanSnackbarOpen(true);
  //     }
  //   }
  // }, [auth.user, router.query.paid]);

  const handleSelectPlay = () => {
    router.push("/play");
  };

  function handleCreateExperiment() {
    // window.location.href = "/new-experiment";
    router.push("/new-experiment");
  }

  // Fetch & set user experiments
  const fetchUserExperiments = async (skip) => {
    try {
      setLoading(true);
      localLogger("access token:", accessToken);
      const res = await getUserExperiments(accessToken, skip, limit);
      logger(res.data);
      setTotalCount(res.data.count);
      setExperiments(res.data);
    } catch (error) {
      console.error(error);
      setError(
        "Sorry, there was an error loading your experiments. Please refresh or try again soon."
      );
    } finally {
      setLoading(false);
    }
  };

  // Update experiments data on page change
  useEffect(() => {
    const skip = (page - 1) * limit;

    if (accessToken) {
      try {
        fetchUserExperiments(skip);
      } catch (err) {
        console.error("Error fetching experiments: ", err);
        setError(
          "Sorry, there was an error retrieving your experiments. Please refresh."
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, auth, auth.user, accessToken]);

  // Callable function to clear messages (for example on user clicks of buttons)
  function clearAlerts() {
    setMessage("");
    setError("");
  }

  return (
    <Container>
      <Box
        sx={{
          width: "100%",
          minHeight: "87.5px",
          px: 2,
          py: 0.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // flexDirection: { xs: "column", md: "row" },
          flexWrap: "wrap",
        }}
      >
        {/* Tutorial button */}
        {/* {experiments?.count > 0 ? (
          <Box
            sx={{
              flex: { xs: undefined, md: 1 },
              order: { xs: 2, md: 1 },
              mt: { xs: 2, md: "0" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Open Tutorial">
              <span>
                <Box
                  variant="outlined"
                  sx={{
                    padding: "2px 7px",
                    border: "1px solid transparent",
                    borderRadius: "4px",
                    "&:hover": { backgroundColor: "rgb(144 202 249 / 19%)" },
                    cursor: "pointer",
                  }}
                  onClick={() => setTutorialOpen(true)}
                >
                  <img
                    src={brainBook}
                    alt="graphic of brain over book"
                    style={{ width: "65px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: ".7rem",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Tutorials
                  </Typography>
                </Box>
              </span>
            </Tooltip>
          </Box>
        ) : (
          <Box flex={1}></Box>
        )} */}
        {/* Title (My Neurorobotics Lab) */}
        <Box
          sx={{
            flex: { xs: undefined, md: 2 },
            display: "flex",
            justifyContent: "center",
            order: { xs: 1, md: 2 },
            width: { xs: "100%", md: "auto" },
          }}
        >
          <SectionHeader
            title="My Neurorobotics Lab"
            size={4}
            textAlign="center"
            alignSelf="center"
          />
        </Box>
        {/* New experiment button */}
        {/* <Box
          sx={{
            flex: { xs: undefined, md: 1 },
            order: 3,
            mt: { xs: 2, md: "0" },
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {experiments?.count > 0 && (
            <Tooltip title="New Experiment">
              <span>
                <Box
                  variant="outlined"
                  sx={{
                    padding: "2px 7px",
                    border: "1px solid transparent",
                    borderRadius: "4px",
                    "&:hover": { backgroundColor: "rgb(144 202 249 / 19%)" },
                    cursor: "pointer",
                  }}
                  onClick={handleCreateExperiment}
                >
                  <img
                    src={testIcon}
                    alt="abstract AI experiment"
                    style={{ width: "65px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: ".7rem",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Create
                  </Typography>
                </Box>
              </span>
            </Tooltip>
          )}
        </Box> */}
      </Box>
      <Container sx={{ padding: "0px !important", width: "100%" }}>
        {/* Mac Intel !Safari warning banner */}
        {/* {!dontShowUnsupportedModal &&
          browserInfo?.isUsingIntelMac &&
          browserInfo?.browser !== "Safari" && (
            <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
              <Alert
                severity="warning"
                sx={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                This browser + system combination may be incompatible with the
                brain visualizer! Launching an experiment could freeze your
                browser.{" "}
                <Button
                  onClick={() => setUnsupportedModalOpen(true)}
                  variant="outlined"
                  size="small"
                  color="warning"
                  // sx={{ ml: 2 }}
                >
                  How to Fix
                </Button>
              </Alert>
            </Box>
          )} */}

        {/* Plan subscription confirmation */}
        {/* <Snackbar
          open={planSnackbarOpen}
          // autoHideDuration={6000}
          onClose={() => setPlanSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={() => setPlanSnackbarOpen(false)} severity="success">
            You are now subscribed to the {auth.user.planId} plan
            <span role="img" aria-label="party" style={{ marginLeft: "10px" }}>
              🥳
            </span>
          </Alert>
        </Snackbar> */}

        {/* Error message */}
        <Snackbar
          open={error}
          onClose={() => setError("")}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          // autoHideDuration={6000}
        >
          <Alert
            onClose={() => setError("")}
            severity="error"
            sx={{ textAlign: "center" }}
          >
            {error}
          </Alert>
        </Snackbar>

        {/* Informative message */}
        <Snackbar
          open={message}
          onClose={() => setMessage("")}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setMessage("")}
            severity="success"
            autoHideDuration={3000}
            sx={{ textAlign: "center" }}
          >
            {message}
          </Alert>
        </Snackbar>

        {/* If no experiments, show Tutorial & New Experiment cards */}
        {/* {experiments && experiments?.count === 0 && (
          <> */}
        {experiments && experiments?.count === 0 && (
          <Typography
            sx={{ mt: 2, fontWeight: "500", color: "accents.superLight" }}
          >
            Welcome to NRS! If you want to play games, select Play. To learn how
            to build a digital brain and create your own AGI behaviors, select
            Learn. Or, to plunge into brain creation sans guidance, choose
            Experiment.
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection:
              experiments?.count > 0 ? "row" : { xs: "column", md: "row" },
            justifyContent: { xs: "space-between", "2xl": "center" },
            alignItems: "center",
            gap: { xs: 2, md: 4 },
            mt: { xs: 2, md: 0 },
          }}
        >
          <TiltCard
            clickAction={handleSelectPlay}
            image="/img/lab-diamond.png"
            text="Play"
            isSmall={experiments?.count > 0}
            disabled={isRunning}
          />
          <TiltCard
            clickAction={() => setTutorialOpen(true)}
            image="/img/lab-cubes.png"
            text="Learn"
            isSmall={experiments?.count > 0}
            // pulse={true}
          />
          <TiltCard
            clickAction={handleCreateExperiment}
            image="/img/lab-box.png"
            text="Create"
            isSmall={experiments?.count > 0}
          />
        </Box>
        {/* </>
        )} */}

        {/* Tutorial modal */}
        {tutorialOpen && (
          <BookModal
            open={tutorialOpen}
            onClose={() => setTutorialOpen(false)}
            setParentError={setError}
            setModalOpen={setTutorialOpen}
            fetchData={getTutorialData}
            header="Foundational Tutorial"
          />
        )}

        {/* Loading spinner */}
        <Box
          sx={{
            mt: 2,
            mb: 1,
            height: !loading ? 2 : "40px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading && <CircularProgress />}
        </Box>

        {experiments && experiments?.count > 0 && (
          <>
            {/* Experiments Table */}
            <Grid item={true}>
              <Box
                sx={{
                  pb: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Experiments
                  experiments={experiments}
                  setError={setError}
                  setMessage={setMessage}
                  clearAlerts={clearAlerts}
                  loading={loading}
                  setLoading={setLoading}
                  isRunning={isRunning}
                  setIsRunning={setIsRunning}
                  setExperiments={setExperiments}
                  refreshExperiments={refreshExperiments}
                  browserInfo={browserInfo}
                  unsupportedModalOpen={unsupportedModalOpen}
                  setUnsupportedModalOpen={setUnsupportedModalOpen}
                  dontShowUnsupportedModal={dontShowUnsupportedModal}
                  setDontShowUnsupportedModal={setDontShowUnsupportedModal}
                  setPostModalAction={setPostModalAction}
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  limit={limit}
                />
              </Box>
            </Grid>
          </>
        )}
      </Container>

      {/* Device unsupported modal */}
      {unsupportedModalOpen && (
        <UnsupportedModal
          browserInfo={browserInfo}
          confirmationOpen={unsupportedModalOpen}
          handleConfirmationClose={() => setUnsupportedModalOpen(false)}
          action={postModalAction}
          dontShowUnsupportedModal={dontShowUnsupportedModal}
          setDontShowUnsupportedModal={setDontShowUnsupportedModal}
        />
      )}
    </Container>
  );
}

export default LabSection;
